body {
	overflow-x: hidden;
}

html,
body {
	background-color: #455a64;
	color: white;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.dancer {
	height: 45vh;
}

.picker-container {
	padding: 2em;
}

.picked {
	font-size: 5rem;
	height: 50vh;
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
}

.participant {
	font-size: 2rem;
	text-transform: uppercase;
}

.flip {
	-moz-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	transform: scaleX(-1);
	-ms-filter: fliph; /*IE*/
	filter: fliph; /*IE*/
}
